import React from "react"
import { Helmet } from "react-helmet-async"
import Thumbnail from "../../assets/images/website-thumbnail.jpg"
import {
  url,
  defaultDescription,
  defaultTitle,
  socialLinks,
  address,
  contact,
  legalName,
  foundingDate,
  logo,
  author,
} from "../../data/config"
import ReactGA from "react-ga"

ReactGA.initialize("UA-198311884-1")

const SEO = ({ title = defaultTitle, description = defaultDescription }) => {
  const structuredDataOrganization = `{ 
		"@context": "http://schema.org",
		"@type": "Organization",
		"legalName": "${legalName}",
		"url": "${url}",
		"logo": "${logo}",
		"foundingDate": "${foundingDate}",
		"founders": [{
			"@type": "Person",
			"name": "${author}"
		}],
		"contactPoint": [{
			"@type": "ContactPoint",
			"email": "${contact.email}",
			"telephone": "${contact.phone}",
			"contactType": "customer service"
		}],
		"address": {
			"@type": "PostalAddress",
			"addressLocality": "${address.city}",
			"addressRegion": "${address.region}",
			"addressCountry": "${address.country}",
			"postalCode": "${address.postalCode}"
		},
		"sameAs": [
			"${socialLinks.google}",
			"${socialLinks.linkedin}",
			"${socialLinks.github}"
		]
  	}`

  return (
    <Helmet>
      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width"
      />
      <meta name="description" content={description} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta
        property="og:image"
        content={`https://hassaanzuberi.com${Thumbnail}`}
      />
      <link
        href="https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap"
        rel="stylesheet"
      />
      <script type="application/ld+json">{structuredDataOrganization}</script>
      <link rel="publisher" href={socialLinks.linkedin} />
      <link rel="canonical" href="https://hassaanzuberi.com/" />
      <title>{title}</title>
      <html lang="en" dir="ltr" />
    </Helmet>
  )
}

export default SEO
