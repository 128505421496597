import { createTheme } from "@material-ui/core/styles"

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#fafafa",
    },
    secondary: {
      main: "#6b63ff",
    },
    background: {
      default: "#fafafa",
    },
  },
  typography: {
    tab: {
      fontWeight: 500,
      fontSize: "1rem",
      color: "#212121",
    },
    h1: {
      fontWeight: "bolder",
      fontSize: "36pt",
      lineHeight: 1.5,
      color: "#212121",
    },
    h2: {
      fontWeight: 700,
      fontSize: "26pt",
      lineHeight: 1.5,
      color: "#212121",
    },
    h3: {
      fontWeight: "normal",
      fontSize: "28pt",
      lineHeight: 1.5,
      color: "#707070",
    },
    h4: {
      fontSize: "1.75rem",
      fontWeight: 500,
      lineHeight: 1.1,
    },
    h6: {
      fontSize: "1.3rem",
      fontWeight: 500,
      lineHeight: 1.75,
      color: `#212121`,
    },
    subtitle1: {
      fontSize: "14pt",
      fontWeight: 400,
      color: `#707070`,
      lineHeight: 1.4,
    },
    body1: {
      fontSize: "18pt",
      fontWeight: "normal",
      color: "#707070",
      lineHeight: 1.5,
    },
    body2: {
      fontSize: "16pt",
      fontWeight: "normal",
      color: "#707070",
      lineHeight: 1.75,
    },
    logoText: {
      fontSize: "1rem",
      fontWeight: 500,
    },
    buttonText: {
      textTransform: "capitalize",
      fontSize: "1rem",
      fontWeight: 500,
      color: "#fff",
    },
  },
  overrides: {
    MuiInputLabel: {
      root: {
        fontSize: "14pt",
        color: "#707070",
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: "15px",
        "&:hover": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "#6b63ff",
            borderWidth: "2px",
            opacity: 1,
          },
        },
      },
      input: {
        color: "#707070",
        padding: "17px 14px",
        fontSize: "14pt",
      },
      notchedOutline: {
        borderColor: "#6b63ff",
        opacity: 0.85,
      },
    },
    MuiLink: {
      root: {
        color: "#6b63ff",
      },
    },
  },
})

export default theme
