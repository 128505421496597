/* eslint-disable import/prefer-default-export, react/prop-types */
import React from "react"
import TopLayout from "./TopLayout"
import { HelmetProvider } from "react-helmet-async"

export const wrapRootElement = ({ element }) => {
  return (
    <HelmetProvider>
      <TopLayout>{element}</TopLayout>
    </HelmetProvider>
  )
}
